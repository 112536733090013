import {AxiosResponse} from 'axios';
import ApiService from '@/services/_helper/api.service';

export default class UserService {
  static getUserList(params: {
    pageNumber: number;
    itemsCount: number;
    username?: string;
    email?: string;
    phoneNumber?: string;
    country?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get('/users/search', {params});
  }
  static getUser(id: number): Promise<AxiosResponse> {
    return ApiService.get(`/users/${id}`);
  }
  static deleteUser(id: number): Promise<AxiosResponse> {
    return ApiService.delete(`/users/${id}`);
  }
  static getUserTransactions(payload: {
    id: number;
    params: {pageNumber: number; itemsCount: number; key?: string};
  }): Promise<AxiosResponse> {
    return ApiService.get(`/users/${payload.id}/transactions`, {params: payload.params});
  }
  static getUserDeviceInfo(id: number): Promise<AxiosResponse> {
    return ApiService.get(`/users/${id}/device-info`);
  }
  static exportUsers(): Promise<AxiosResponse> {
    return ApiService.get('/users/export');
  }
  static getUserReferrals(id: number): Promise<AxiosResponse> {
    return ApiService.get(`/users/${id}/invited`);
  }
  static addInvitationBonus(payload: {id: number; params: {invitationBonus: number | null}}): Promise<AxiosResponse> {
    return ApiService.put(`/users/${payload.id}/invitation-bonus`, payload.params);
  }
  static unlockDeviceId(payload: {id: number; params: {deviceId: string | null}}): Promise<AxiosResponse> {
    return ApiService.put(`/users/${payload.id}/unlock-device-id`, payload.params);
  }
  static getMasterWallet(): Promise<AxiosResponse> {
    return ApiService.get(`/users/master-wallet`);
  }
  static removeFromWaitlist(userIds: number[]): Promise<AxiosResponse> {
    return ApiService.delete('/users/waitlist', {data: {userIds: userIds}});
  }
  static getUsersRoles(): Promise<AxiosResponse> {
    return ApiService.get(`/users/roles`);
  }
  static sendMarketing(payload: {title: string; body: string; roles: string[]}): Promise<AxiosResponse> {
    return ApiService.post(`/users/send-marketing`, payload);
  }
}
