import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "no-data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return ($props.consentList && $props.consentList.length)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createVNode(_component_a_table, {
          class: "table",
          dataSource: $props.consentList,
          rowKey: "acceptedOn",
          columns: $setup.columns,
          scroll: {x: 960},
          pagination: false
        }, null, 8, ["dataSource", "columns"]),
        _createVNode(_component_a_pagination, {
          current: $setup.pageNumber,
          "onUpdate:current": _cache[0] || (_cache[0] = ($event: any) => (($setup.pageNumber) = $event)),
          total: $setup.totalItems,
          pageSize: $setup.itemsCount,
          "onUpdate:pageSize": _cache[1] || (_cache[1] = ($event: any) => (($setup.itemsCount) = $event)),
          "show-size-changer": "",
          "show-quick-jumper": $setup.totalItems / $setup.itemsCount > 5,
          onShowSizeChange: $setup.onShowSizeChange,
          onChange: $setup.onPageChange
        }, null, 8, ["current", "total", "pageSize", "show-quick-jumper"])
      ], 64))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, "No consents received yet"))
}