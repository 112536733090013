import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "no-data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.loading)
      ? (_openBlock(), _createBlock($setup["Loader"], { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_a_input, {
      class: "search-input",
      value: $setup.key,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.key) = $event)),
      "allow-clear": "",
      onChange: $setup.onSearchInput,
      placeholder: "Search by key",
      disabled: ""
    }, null, 8, ["value"]),
    ($setup.dataSource && $setup.dataSource.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_a_table, {
            class: "table",
            dataSource: $setup.dataSource,
            rowKey: "id",
            columns: $setup.columns,
            scroll: {x: 745},
            pagination: false
          }, null, 8, ["dataSource", "columns"]),
          _createVNode(_component_a_pagination, {
            current: $setup.pageNumber,
            "onUpdate:current": _cache[1] || (_cache[1] = ($event: any) => (($setup.pageNumber) = $event)),
            total: $setup.totalItems,
            pageSize: $setup.itemsCount,
            "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => (($setup.itemsCount) = $event)),
            "show-size-changer": "",
            "show-quick-jumper": $setup.totalItems / $setup.itemsCount > 5,
            onShowSizeChange: $setup.onShowSizeChange,
            onChange: $setup.onPageChange
          }, null, 8, ["current", "total", "pageSize", "show-quick-jumper"])
        ], 64))
      : (!$setup.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "No transactions made yet"))
        : _createCommentVNode("", true)
  ], 64))
}