import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "no-data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.loading)
      ? (_openBlock(), _createBlock($setup["Loader"], { key: 0 }))
      : _createCommentVNode("", true),
    ($setup.dataSource && $setup.dataSource.length)
      ? (_openBlock(), _createBlock(_component_a_table, {
          key: 1,
          class: "table",
          dataSource: $setup.dataSource,
          rowKey: "id",
          columns: $setup.columns,
          scroll: {x: 900},
          pagination: false
        }, null, 8, ["dataSource", "columns"]))
      : (!$setup.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "No referrals made yet"))
        : _createCommentVNode("", true)
  ], 64))
}